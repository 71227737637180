import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import TitleBlok from '../../components/TitleBlok/TitleBlok';
import companyFormsHttp from '../../http/companyFormsHttp/companyFormsHttp';
import Pagination from '../../components/Pagination/Pagination';
import CompanyFormModal from '../../components/Modal/CompanyFormModal';
import InfoListModal from '../../compo../../components/Modal/InfoListModal'
import TableClients from '../../components/DataTable/TableClients';
import { columnsCompanyFormsAdmin } from './helpers/CompanyFormsUtils';

export default function CompanyForms() {
  const navigate = useNavigate();
  const [companyFormList, setCompanyFormList] = useState([]);
  const [companyFormListPage, setCompanyFormListDataPage] = useState();
  const [newLoad, setNewLoad] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [companyFormId, setCompanyFormId] = useState();
  const [isOpenEmployee, setIsOpenEmployee] = useState(false);
  const [List, setList] = useState();

  useEffect(() => {
    companyFormsHttp.getAllCompanyForms('').then(e => {
      setCompanyFormListDataPage(e?.data);
      setCompanyFormList(e?.data?.data);
    });
  }, [newLoad]);

  const onPaginationClick = id => {
    companyFormsHttp.getAllCompanyForms(id).then(e => {
      setCompanyFormListDataPage(e?.data);
      setCompanyFormList(e?.data?.data);
    });
  };

  const onClickRemove = id => {
    setIsOpen(true);
    setCompanyFormId(id);
  };

  const onClickYes = id => {
    companyFormsHttp.deleteCompanyForms(id).then(e => e.status === 200 && setNewLoad(!newLoad));
    setIsOpen(false);
  };
  const onClickEmployeeList = list => {
    if(list?.length > 1) {
      setIsOpenEmployee(true);
      setList(list);
    }
  };

  const onClickEdit = id => {
    navigate(`/Admin/EditCompanyForm/${id}`);
  };

  return (
    <div className="w-full sm:p-8 p-2">
      <CompanyFormModal open={isOpen} setOpen={setIsOpen} onClickYes={onClickYes} companyFormId={companyFormId} />
      <InfoListModal
        open={isOpenEmployee}
        setOpen={setIsOpenEmployee}
        List={List}

      />
      <TitleBlok src={'/CompanyFormsIcon.svg'} textItem={'Company Forms Employees'} className="text-base font-bold">
        <div className="flex sm:gap-4 gap-2">
          <Button
            onClick={() => navigate('/Admin/CreateCompanyForm')}
            buttonText={'Create Company Form'}
            className={'text-[#fff] sm:text-sm text-xs'}
          />
        </div>
      </TitleBlok>
      <div className="px-4 py-1 mt-4 bg-white rounded-xl">
        <div className="w-full overflow-x-auto">
          <div className="inline-block min-w-full">
            <TableClients
              maxHeight="500px"
              tbody={companyFormList ?? []}
              columns={columnsCompanyFormsAdmin(onClickEmployeeList, onClickEdit, onClickRemove)}
            />
          </div>
        </div>
        <Pagination onPaginationClick={onPaginationClick} paginationData={companyFormListPage?.links} />
      </div>
    </div>
  );
}
