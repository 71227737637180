import { useEffect, useState } from 'react';
import TitleBlok from '../../components/TitleBlok/TitleBlok';
import Pagination from '../../components/Pagination/Pagination';
import TableClients from '../../components/DataTable/TableClients';
import { columnsMyRequestsEmployee } from './helpers/MyRequestsEmployeeUtils';
import myRequestsEmployeeHttp from '../../http/myRequestsEmployeeHttp/myRequestsEmployeeHttp';
import { useNavigate } from 'react-router-dom';
import { afterAPICall } from '../Payments/helpers/PaymentUtils';
import requestHttp from '../../http/requestHttp/requestHttp';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

export default function MyRequestsEmployee() {
  const [myRequestsList, setMyRequestsList] = useState([]);
  const [myRequestsListPage, setMyRequestsListPage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);

  const navigate = useNavigate();

  const fetchMyRequests = async (pageId = '') => {
    setLoading(true);
    try {
      const response = await myRequestsEmployeeHttp.employeeRequestsLinks(pageId);
      setMyRequestsList(response?.data?.data || []);
      setMyRequestsListPage(response?.data || {});
    } catch (err) {
      console.error('Error fetching company forms:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMyRequests();
  }, [reload]);

  const handlePaginationClick = id => {
    fetchMyRequests(id);
  };

  const onClickDetails = id => {
    navigate(`/Employee/ViewRequestForm/${id}`);
  };

  const onClickComplete = id => {
    requestHttp
      .completeRequestForEmployee(id)
      .then(e => afterAPICall('Status Successfully Changed'))
      .then(() => setReload(prev => !prev));
  };
  const onClickUnComplete = id => {
    requestHttp
      .unCompleteRequestForEmployee(id)
      .then(e => afterAPICall('Status Successfully Changed'))
      .then(() => setReload(prev => !prev));
  };

  return (
    <div className="sm:px-8 px-4 py-8 w-full">
      <TitleBlok textItem="My Requests" className="text-base font-semibold" src="/CompanyFormsIcon.svg" />
      <div className="px-4 py-1 mt-4 bg-white rounded-xl">
        <div className={`w-full overflow-x-auto  ${loading && `animate-pulse`}`}>
          <div className="inline-block min-w-full">
            <TableClients
              tbody={myRequestsList}
              columns={columnsMyRequestsEmployee(onClickDetails, onClickComplete,onClickUnComplete)}
              maxHeight="500px"
            />
          </div>
        </div>
        <Pagination onPaginationClick={handlePaginationClick} paginationData={myRequestsListPage?.links} />
      </div>
      <ToastContainer />
    </div>
  );
}
