import classNames, { onlyNumbers } from "../../utils/utils";
import  debounce  from "debounce";

export default function TitleBlockRequestServiceLogsClient({ textItem, children, className, classNameTitleBlock, setDateId, AllEmployeesData, setDataEmployeeCurrent,setCurrentPage,}) {

    const debouncedOnChange = debounce((e) => {
        setDateId(e?.target?.value)
        setCurrentPage(1)
    }, 500);

    const onChangeEmployees = (e) => {
        setDataEmployeeCurrent(e.target.value)
        setCurrentPage(1)
    }

    return (
        <div className={classNames("flex justify-between items-center bg-white py-2 sm:px-4 px-2 rounded-lg", classNameTitleBlock)}>
            <div className={AllEmployeesData?.length ? "flex lg:flex-row flex-col items-center gap-2  w-full pr-2" : "flex sm:flex-row flex-col items-center gap-2 md:w-3/5 w-4/5 w-full pr-2"}>
                <img src="/TitleBlokIcon.svg" alt="img"/>
                <p className="w-full">
                    <span className={classNames("font-['Poppins']", className
                    )}>{textItem}</span>
                </p>
            
                <div className="relative w-full">
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <img src="/search.svg" alt="img"/>
                    </div>
                    <input onKeyPress={onlyNumbers} placeholder="search by id" onChange={debouncedOnChange} className="w-full outline-none border rounded p-1 text-xs sm:text-base h-[34px]" />
                </div>

                {AllEmployeesData?.length != 0 &&
                        <div className="w-full">
                            <select
                                id="location"
                                className={"w-full box-content focus:outline-none h-[32px] border rounded cursor-pointer text-xs sm:text-base"}
                                // value={value}
                                onChange={onChangeEmployees}
                            >
                                {AllEmployeesData?.map((e, index) => (
                                    <option key={index}
                                        // disabled={indexx > 0 ? disable?.includes(e?.id): false} 
                                        value={e?.id} className="cursor-pointer">{e?.name ?? e?.contactName}</option>
                                ))}
                            </select>
                        </div>}
            </div>
            {children}
        </div >
    )
}
