import { $authHost } from "..";

export default class unbillableHoursHttp {
    static unbillableServiceLogAddLinks(data) {
        return $authHost.post(`/employee/ubillableServiceLog`, data)
    };
    static unbillableHoursDataLinks(dateRange, id) {
        return $authHost.get(`/employee/ubillableServiceLog?startDate=${dateRange?.startDate ?? ""}&endDate=${dateRange?.endDate ?? ""}&id=${id ?? ""}`)
    };
    static unbillableHoursDataLinksId(id) {
        return $authHost.get(`/employee/ubillableServiceLog?page=${id}`)
    };

    // static serviceLogClientsLinks() {
    //     return $authHost.get(`/employee/get/clients`)
    // };
    // static serviceLogServicesLinks(id) {
    //     return $authHost.get(`/employee/get/services/${id}`)
    // };
    // static serviceLogPositionsLinks(id) {
    //     return $authHost.get(`/employee/get/positions/${id}`)
    // };
    static ubillableServiceLogWeekLinks(id) {
        return $authHost.get(`/employee/ubillableServiceLog/week/${id}`)
    };

    static currentubillableServiceLogLinks(id) {
        return $authHost.get(`/employee/ubillableServiceLog/${id}`)
    };
    static deleteCurrentubillableServiceLogLinks(id) {
        return $authHost.post(`/employee/removeUnbillableLog/${id}`)
    };
    static updateubillableServiceLogLinks(id, data) {
        return $authHost.post(`/employee/ubillableServiceLog/${id}`, data)
    };
    static serviceLogDeferenceLinks(data) {
        return $authHost.post(`/employee/distance`, data)
    };
}