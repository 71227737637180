
export const columnsRequestServiceLogsClient = (
  onClickView,
  dataPage,
  onClickEmployeeList
) => [
  {
    accessorKey: 'id',
    header: 'Id', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 30,
    Cell: ({ row }) => <p className="whitespace-nowrap">#{row?.original?.id}</p>,
  },
  {
    accessorKey: 'employee',
    header: 'Employee', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => 
    <p className={`whitespace-nowrap ${row?.original?.['employees']?.length >1 &&"cursor-pointer"}`} onClick={()=>onClickEmployeeList(row?.original?.['employees'])}>{ row?.original?.['employees']?.map(
      (a, index, array) => `${a + `${index !== array?.length - 1 ? `,` : ' '} `}`,
    )}</p>,
  },
  {
    accessorKey: 'Provider Company',
    header: 'Provider Company', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.partner ?? ''}</p>,
  },
  {
    accessorKey: 'request',
    header: 'Request Name', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.request_name ?? ''}</p>,
  },
  {
    accessorKey: 'hours',
    header: 'Hours', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 50,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.hours ?? ''}</p>,
  },
  {
    accessorKey: 'Action',
    header: 'Action',
    // size: 250, //decrease the width of this column
    // minSize: 350, //decrease the width of this column
    // maxSize: 250,
    Cell: ({ row }) => (
      <div className="flex gap-2 min-w-[300px]">
        <button
          onClick={() =>
            onClickView(
              row?.original?.id,
              row?.original?.id,
              row?.original?.is_send,
              dataPage?.links?.current_page,
            )
          }
          className="text-[#4885ED] block ml-2 border px-4 border-[#4885ED] rounded py-1 text-sm hover:text-indigo-900">
          View
        </button>
      </div>
    ),
  },
];
