

export const AddServiceLogRequestDefaultValue = (requestsData) => {
    const Startdate = new Date()
    Startdate.setMinutes("00")
    Startdate.setHours("10")
    Startdate.setSeconds('00');

    const Enddate = new Date()
    Enddate.setMinutes("00")
    Enddate.setHours("11")
    Enddate.setSeconds('00');

    return {
        school_id: requestsData?.[0]?.school_id,
        service_id: requestsData?.[0]?.service_id,
        serviceLogTimeActivities: [{
            start_time: Startdate,
            end_time: Enddate,
            activiteee : {},
            position_id:"",
        }],
    }
}

export default AddServiceLogRequestDefaultValue
