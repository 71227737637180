import * as yup from 'yup';

export const RequestErrorAssignFormToClient = () => {
  return yup.object().shape({
    // school_ids: isEmpty ? yup.array().min(1).required('This field is required') : yup.array(),
    school_id: yup.string().required('This field is required'),
    form_id: yup.string().required('This field is required'),
  });
};

export default RequestErrorAssignFormToClient;
