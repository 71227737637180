export const columnsEditFormAdmin = onClickView => [
  {
    accessorKey: 'employee',
    header: 'Employee', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['employee']}</p>,
  },
  {
    accessorKey: 'hours',
    header: 'Hours', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 50,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['hours']}</p>,
  },
  {
    accessorKey: 'action',
    header: 'Action', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 50,
    Cell: ({ row }) => (
      <div className="flex gap-2">
        <button
          type="button"
          onClick={() => onClickView(row?.original?.employee_id)}
          className="text-[#4885ED] block border px-4 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900">
          View
        </button>
      </div>
    ),
  },
];

export const columnsRequestLogViewDataAdmin = (viewData, onClickCommentShow) => [
  {
    accessorKey: 'date',
    header: 'Date', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 50,
    Cell: ({ row }) => (
      <div onClick={() => console.log(row?.original, 'row')}>
        <p className="whitespace-nowrap">{row?.original?.day}</p>
        <p className="whitespace-nowrap">{row?.original?.name}</p>
      </div>
    ),
  },
  {
    accessorKey: 'start time',
    header: 'Start Time', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 40,
    Cell: ({ row }) =>
      row?.original?.['service logs']?.map((elem, index) => (
        <p className={`whitespace-nowrap ${elem?.is_request_log && 'text-[#00B1B2]'} `} key={index}>
          {elem?.['Start Time']}
        </p>
      )),
  },
  {
    accessorKey: 'end time',
    header: 'End Time', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 60,
    Cell: ({ row }) =>
      row?.original?.['service logs']?.map((elem, index) => (
        <div className="flex" key={index}>
          <p className={`whitespace-nowrap ${elem?.is_request_log && 'text-[#00B1B2]'} `}>{elem?.['End Time']}</p>
          
          
        </div>
      )),
  },
  {
    accessorKey: 'Total Time',
    header: 'Total Time', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 40,
    Cell: ({ row }) =>
      row?.original?.['service logs']?.map((elem, index) => (
        // <div>
        <p className={`whitespace-nowrap ${elem?.is_request_log && 'text-[#00B1B2]'} `} key={index}>
          {elem?.['Total Time']}
        </p>
      )),
  },
  {
    accessorKey: 'client',
    header: 'Client', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 30,
    Cell: ({ row }) =>
      row?.original?.['service logs']?.map((elem, index) => (
        // <div>
        <p className={`whitespace-nowrap ${elem?.is_request_log && 'text-[#00B1B2]'} `} key={index}>
          {elem?.['client']}
        </p>
      )),
  },
  {
    accessorKey: 'request',
    header: 'Request Name', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 30,
    Cell: ({ row }) =>
      row?.original?.['service logs']?.map((elem, index) => (
        // <div>
        <p className={`whitespace-nowrap ${elem?.is_request_log && 'text-[#00B1B2]'} `} key={index}>
          {elem?.['form_name'] ?? ' - '}
        </p>
      )),
  },
  {
    accessorKey: 'Positions',
    header: 'Position', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 30,
    Cell: ({ row }) =>
      row?.original?.['service logs']?.map((elem, index) => (
        // <div>
        <p className={`whitespace-nowrap ${elem?.is_request_log && 'text-[#00B1B2]'} `} key={index}>
          {elem?.['Positions']}
        </p>
      )),
  },
  {
    accessorKey: 'activity',
    header: 'Description', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 30,
    Cell: ({ row }) =>
      row?.original?.['service logs']?.map((elem, index) => (
        <li
          className={`whitespace-nowrap overflow-hidden cursor-pointer ${elem?.is_request_log && 'text-[#00B1B2]'} `}
          key={index}
          onClick={() =>
            onClickCommentShow({
              id: elem?.['id'],
              day: row?.original?.day,
              start: elem?.['Start Time'],
              end: elem?.['End Time'],
              comment: `${elem?.['activity']?.[0]?.label} ${elem?.rush == 0 ? '' : ' (Rush)'}`,
            })
          }>
          <span>{elem?.['activity']?.[0]?.label}</span>
          <span>{elem?.rush == 0 ? '' : ' (Rush)'}</span>
        </li>
      )),
  },
];
