import classNames, { onlyNumbers } from '../../utils/utils';
import WeekDataPicker from '../WeekDataPicker/WeekDataPicker';
import debounce from 'debounce';

export default function TitleBlokEmployee({
  textItem,
  children,
  className,
  classNameTitleBlock,
  setDateRange,
  setDateId,
  setDataEmployeeCurrent,
  AllEmployeesData,
}) {
  const debouncedOnChange = debounce(e => {
    setDateId(e?.target?.value);
  }, 500);

  return (
    <div
      className={classNames(
        'flex justify-between items-center bg-white py-2 sm:px-4 px-2 rounded-lg',
        classNameTitleBlock,
      )}>
      <div
        className={
          AllEmployeesData?.length
            ? 'flex sm:flex-row flex-col items-center gap-2  w-full pr-2'
            : 'flex sm:flex-row flex-col items-center gap-2 md:w-3/5 w-4/5 w-full pr-2'
        }>
        <img src="/TitleBlokIcon.svg" alt="img" />
        <p className="w-full">
          <span className={classNames("font-['Poppins']", className)}>{textItem}</span>
        </p>
        <WeekDataPicker setDateRange={setDateRange} />
        {/* <div className="mt-2">
          <div className="flex rounded-md bg-white outline outline-1 -outline-offset-1 outline-gray-300 focus-within:outline focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-indigo-600">
            <input
             onKeyPress={onlyNumbers} placeholder="search by id" onChange={debouncedOnChange}
              className="block min-w-0 grow px-3 py-1.5 text-base text-gray-900 placeholder:text-gray-400 focus:outline focus:outline-0 sm:text-sm/6"
            />
            <div className="flex py-1.5 pr-1.5">
              <kbd className="inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400">
              <img src="/search.svg" alt="img"/>
              </kbd>
            </div>
          </div>
        </div> */}
        <div className="relative w-full">
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <img src="/search.svg" alt="img" />
          </div>
          <input
            onKeyPress={onlyNumbers}
            placeholder="search by id"
            onChange={debouncedOnChange}
            className="w-full outline-none border rounded p-1 text-xs sm:text-base h-[34px]"
          />
        </div>
        {AllEmployeesData?.length && (
          <div className="w-full">
            <select
              id="location"
              className={
                'w-full box-content focus:outline-none h-[32px] border rounded cursor-pointer text-xs sm:text-base'
              }
              // value={value}
              onChange={e => setDataEmployeeCurrent(e.target.value)}>
              {AllEmployeesData?.map((e, index) => (
                <option
                  key={index}
                  // disabled={indexx > 0 ? disable?.includes(e?.id): false}
                  value={e?.id}
                  className="cursor-pointer">
                  {e?.name ?? e?.contactName}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
      {children}
    </div>
  );
}
