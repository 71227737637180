import { useEffect, useMemo, useState } from 'react';
import CancleAdd from '../../components/CancleAdd/CancleAdd';
import Title from '../../components/Title/Title';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { notifySuccess } from '../../utils/utils';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import RequestErrorAssignRequestToEmployee from './RequestErrorAssignRequestToEmployee';
import requestHttp from '../../http/requestHttp/requestHttp';
import EmployeesHttp from '../../http/employeesHttp/employeesHttp';
import InputLabel from '../../components/InputLabel/InputLabel';
import AssignRequestToEmployeeDefaultValue from './AssignRequestToEmployeeDefaultValue';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AssignRequestToEmployee() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const params = useParams();
  const { state } = useLocation();

  const isEmpty = state?.employees?.length == 0;

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    register,
    reset,
  } = useForm({
    defaultValues: useMemo(() => AssignRequestToEmployeeDefaultValue(data), [data]),
    resolver: yupResolver(useMemo(() => RequestErrorAssignRequestToEmployee(isEmpty), [isEmpty])),
  });

  const navigate = useNavigate();
  const [allEmployeesData, setAllEmployeesData] = useState([]);

  useEffect(() => {
    EmployeesHttp.employeesFullinks().then(e => {
      const uniqueIdsSet = new Set();
      const employesList = e?.data?.data
        ?.map(item => ({
          value: item?.id || item?.[0]?.employee_id,
          label: item?.name || item?.[0]?.name,
        }))
        .reduce((result, item) => {
          if (!uniqueIdsSet.has(item.value)) {
            uniqueIdsSet.add(item.value);
            result.push(item);
          }
          return result;
        }, []);
      employesList.unshift({ value: 'All', label: 'All Employees' });
      setAllEmployeesData(employesList);
    });
  }, []);

  const afterSubmit = () => {
    notifySuccess('Request Successfully Assigned');
    setTimeout(() => {
      navigate('/Admin/Requests');
    }, 2000);
  };

  const onSubmit = async data => {
    setIsLoading(true);
    data.employee_ids = data?.employee_ids || [];
    requestHttp
      ?.assignRequestFormToEmployees(params?.id, data)
      .then(() => afterSubmit())
      .catch(err => {
        const errorFromBackend = Object.keys(err?.response?.data?.errors);
        errorFromBackend.forEach(item =>
          setError(item, { type: 'custom', message: err?.response?.data?.errors?.[item] }),
        );
        setIsLoading(false);
      });
  };

  const OnClickCancle = () => {
    navigate('/Admin/Requests');
  };

  useEffect(() => {
    requestHttp?.getSubmittedEmployeesAdmin(params?.id).then(e => setData(e?.data?.data));
  }, []);

  useEffect(() => {
    reset(AssignRequestToEmployeeDefaultValue(data));
  }, [data]);

  return (
    <div className="sm:px-8 px-2 py-8 w-full ">
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title
            title={`${data?.employees?.length > 0 ? 'Edit Assigned' : 'Assign'} employees to ${data?.name}`}
            className="border-b py-1"
            classNameTitle={'text-2xl font-semibold'}
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className="w-full bg-white p-4 rounded-md">
            <div className="grid  items-end">
              <div className="w-full bg-white">
                <MultiSelect
                  title={'To Employees'}
                  selectData={allEmployeesData}
                  selectClass="flex-col gap-2"
                  name={'employee_ids'}
                  All="All Employees"
                  defalutValue={
                    data?.employees?.length > 0
                      ? data?.employees?.map(elem => {
                          return { value: elem?.id, label: elem?.name };
                        })
                      : []
                  }
                  className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer h-full"
                  classNameTitle="text-[#60657B] opacity-50 text-xs"
                  setValue={setValue}
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.employee_ids?.message}</p>}
              </div>
            </div>
            <div className="mt-5">
              <InputLabel
                classNameInput="flex flex-col"
                classNameLabel={'text-[#60657B] text-xs font-normal p-1 opacity-50'}
                className="w-full border p-[7px]"
                type="date"
                labelTitle={'DeadLine of Request'}
                // max={today}
                register={register}
                name={'deadline'}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.deadline?.message}</p>}
            </div>
          </div>
          <div className="mt-40 z-50">
            <CancleAdd disabled={isLoading} buttonText={`Assign`} onClickCancle={OnClickCancle} />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
