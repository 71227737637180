import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';

import Title from '../../components/Title/Title';
import InputLabel from '../../components/InputLabel/InputLabel';
import CheckboxesFields from './components/CheckboxesFields';
import ViewRequestFormEmployeeDefaultValue from './ViewRequestFormEmployeeDefaultValue';
import requestHttp from '../../http/requestHttp/requestHttp';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '../../components/Button/Button';

export default function ViewRequestFormEmployee() {
  const [form, setForm] = useState([]);

  const params = useParams();
  const navigate = useNavigate();

  const OnClickCancle = () => {
    navigate('/Employee/MyRequests');
  };

  const {
    register,
    control,
    reset,
  } = useForm({
    defaultValues: useMemo(() => ViewRequestFormEmployeeDefaultValue(form), [form]),
  });

  const { fields: Fields } = useFieldArray({
    control,
    name: 'formFields',
  });


  useEffect(() => {
    reset(ViewRequestFormEmployeeDefaultValue(form));
  }, [form]);

  useEffect(() => {
    requestHttp.getSubmittedFormClient(params?.id).then(e => {
      setForm(e?.data?.data);
    });
  }, []);

  return (
    <div className="sm:px-8 px-2 py-8 w-full">
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title
            title={`Edit Form for ${form?.title}`}
            className="border-b py-1"
            classNameTitle={'text-2xl font-semibold'}
          />
        </div>
        <form className="w-full">
          <div className="py-4 w-full">
            <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-3 sm:gap-6 gap-2 items-baseline">
              {Fields?.map(({ label, type, id }, index) =>
                type === 'input' ? (
                  <div className="w-full" key={id}>
                    <InputLabel
                      labelTitle={label}
                      register={register}
                      name={`formFields.${index}.value`}
                      classNameLabel="text-[#60657B] opacity-50 text-xs"
                      classNameInput="flex flex-col flex-1"
                      type={'text'}
                      placeholder={label}
                      disabled={true}
                      className="w-full"
                      required={{ required: 'This field is required' }}
                    />
                  </div>
                ) : (
                  type === 'date' && (
                    <div className="" key={id + label}>
                      <InputLabel
                        classNameInput="flex flex-col "
                        classNameLabel={'font-[system-ui] text-[#60657B] text-xs font-normal opacity-50'}
                        className="w-full border p-[7px]"
                        type="date"
                        labelTitle={label}
                        register={register}
                        name={`formFields.${index}.value`}
                        required={{ required: 'This field is required' }}
                        disabled={true}
                      />
                    </div>
                  )
                ),
              )}
            </div>
            <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-3 sm:gap-6 gap-2 items-baseline mt-5 h-full">
              {Fields?.map(
                ({ name, label, type, id }, index) =>
                  type === 'checkbox' && (
                    <div className="space-y-5 h-full" key={id + name + label}>
                      <p className="text-lg font-semibold">{label}</p>
                      <CheckboxesFields control={control} register={register} index={index} disabled={true} />
                    </div>
                  ),
              )}
            </div>
            <div className="grid sm:px-7 px-4 sm:grid-cols-2 sm:gap-6 gap-2 items-baseline mt-5 h-full">
              <div>
                <InputLabel
                  labelTitle={'Request Name'}
                  className="border-b-[0.5px] box-border px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                  placeholder={'Request Name'}
                  name={`name`}
                  register={register}
                  classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
                  required={{ required: 'This field is required' }}
                  disabled={true}
                />
              </div>
              {/* {form?.acceptable_hours ? (
                <CurrencyInputNew
                  classNameLabel="text-[#60657B] opacity-50 text-xs font-normal mb-2"
                  className={'w-full border outline-none rounded border p-4 text-start h-[43px]'}
                  name={`acceptable_hours`}
                  setValue={setValue}
                  placeholder="Acceptable Hours"
                  defaultValue={+form?.acceptable_hours.replace(':', '.')}
                  labelTitle={'Acceptable Hours'}
                  prefix=""
                  decimalSeparator=":"
                  disabled={true}
                />
              ) : (
                <CurrencyInputNew
                  classNameLabel="text-[#60657B] opacity-50 text-xs font-normal mb-2"
                  className={'w-full border outline-none rounded border p-4 text-start h-[43px]'}
                  name={`acceptable_hours`}
                  setValue={setValue}
                  placeholder="Acceptable Hours"
                  labelTitle={'Acceptable Hours'}
                  prefix=""
                  decimalSeparator=":"
                  disabled={true}
                />
              )} */}
              {/* {form?.hourly_rate ? (
                <CurrencyInputNew
                  classNameLabel="text-[#60657B] opacity-50 text-xs font-normal mb-2"
                  className={'w-full border outline-none rounded border p-4 text-start h-[43px]'}
                  name={`hourly_rate`}
                  setValue={setValue}
                  placeholder="Hourly Rate $ 0.00"
                  defaultValue={form?.hourly_rate}
                  labelTitle={'Hourly Rate'}
                  disabled={true}
                />
              ) : (
                <CurrencyInputNew
                  classNameLabel="text-[#60657B] opacity-50 text-xs font-normal mb-2"
                  className={'w-full border outline-none rounded border p-4 text-start h-[43px]'}
                  name={`hourly_rate`}
                  setValue={setValue}
                  placeholder="Hourly Rate $ 0.00"
                  labelTitle={'Hourly Rate'}
                  disabled={true}
                />
              )} */}
              <div>
                <InputLabel
                  labelTitle={'Service'}
                  className="border-b-[0.5px] box-border px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                  placeholder={'Service'}
                  name={`service`}
                  register={register}
                  classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
                  required={{ required: 'This field is required' }}
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="flex gap-2 justify-end border-t py-2 sm:pr-8 sm:pr-2 pr-4 mt-2">
              <Button
                buttonText={'Back'}
                onClick={OnClickCancle}
                className="bg-inherit border text-[#000] box-border px-3"
                type="button"
              />
            </div>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
