

export const CreateCusomLogDefaultValue = () => {
 
    return {
        is_subsidiary_client : false,
        school_id : "",
        included_request_log:"false"
    }
  
}

export default CreateCusomLogDefaultValue