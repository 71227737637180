import { $authHost, $authHostt } from "..";

export default class clientServiceLogHttp {
    static serviceLogAddLinks(data) {
        return $authHost.post(`/employee/serviceLog`, data)
    };
    static getClientsForClient() {
        return $authHost.get('/client/getSchools');
      };
    static serviceLogDataLinks(dateRange, id,dataEmployeeCurrent,dataClientCurrent,isDesc,page) {
        return $authHost.get(`/client/serviceLog?startDate=${dateRange?.startDate ?? ""}&endDate=${dateRange?.endDate ?? ""}&id=${id ?? ""}&employee_id=${dataEmployeeCurrent ?? ""}&school_id=${dataClientCurrent?? ""}&page=${page ?? ""}&sort_direction=${isDesc??"desc"}`)
    };
    static customServiceLogDataLinks(dateRange, id,dataEmployeeCurrent,dataClientCurrent,isDesc,page) {
        return $authHost.get(`/client/customLog?startDate=${dateRange?.startDate ?? ""}&endDate=${dateRange?.endDate ?? ""}&id=${id ?? ""}&employee_id=${dataEmployeeCurrent ?? ""}&school_id=${dataClientCurrent?? ""}&page=${page ?? ""}&sort_direction=${isDesc??"desc"}`)
    };
    static serviceLogDataLinksId(id) {
        return $authHost.get(`/employee/serviceLog?page=${id}`)
    };
    static serviceLogClientsLinks() {
        return $authHost.get(`/employee/get/clients`)
    };
    static serviceLogServicesLinks(id) {
        return $authHost.get(`/employee/get/services/${id}`)
    };
    static serviceLogPositionsLinks(id) {
        return $authHost.get(`/employee/get/positions/${id}`)
    };
    static serviceLogWeekLinks(id) {
        return $authHost.get(`/employee/serviceLog/week/${id}`)
    };
    static currentserviceLogLinks(id) {
        return $authHost.get(`/employee/serviceLog/${id}`)
    };

    static serviceLogDeferenceLinks(data) {
        return $authHost.post(`/employee/distance`, data)
    };
    static serviceLogAdminDataLinksId(id) {
        return $authHost.get(`/admin/serviceLog?page=${id}`)
    };
    static serviceLogSendGetListLogs(client_id,dateRange) {
        return $authHost.get(`/admin/serviceLogByClient/${client_id}?startDate=${dateRange?.startDate ?? ""}&endDate=${dateRange?.endDate ?? ""}`)
    };
    static serviceLogWeekAdminLinks(id,employeId,partner_id) {
        return $authHost.get(`/client/serviceLog/week/${id}/employee/${employeId}/partner/${partner_id}`)
    };
    static serviceLogWeekAdminPDFLinks(id,employeId,partner_id,client_id) {
        return $authHostt.get(`/client/pdf/week/${id}/employee/${employeId}/client/${client_id}/partner/${partner_id}`)
        // client to partner
    };
    static customLogWeekAdminPDFLinks(id) {
        return $authHostt.get(`/client/customLog/pdf/${id}`)
        // client to partner
    };
    static serviceLogWeekAdminSendLinks(id,employeId,clientId) {
        return $authHostt.post(`/admin/send/pdf/week/${id}/employee/${employeId}/client/${clientId}`)
    };
    static serviceLogRemaindAdminLinks(id,employeId) {
        return $authHost.post(`/admin/remind/week/${id}/employee/${employeId}`)
    };
    static serviceLogDeleteAdminLinks(id,employeId) {
        return $authHost.post(`/admin/removeWeek/week/${id}/employee/${employeId}`)
    };
    static serviceLogDiscardAdminLinks(id,employeId) {
        return $authHost.post(`/admin/discard/week/${id}/employee/${employeId}`)
    };
    static serviceLogApporveAdminLinks(id,employeId) {
        return $authHost.post(`/admin/approve/week/${id}/employee/${employeId}`)
    };
    static serviceLogDisApporveAdminLinks(id,employeId) {
        return $authHost.post(`/admin/disapproveServiceLog/week/${id}/employee/${employeId}`)
    };
    static serviceLogSendLogsLinks(data) {
        return $authHost.post(`/admin/serviceLog/sendPdfs`,data)
    };
    static serviceLogGetCurrentLinks(id) {
        return $authHost.get(`/admin/getServiceLog/${id}`)
    };
    static serviceLogDeleteCurrentLinks(id) {
        return $authHost.post(`/admin/removeServiceLog/${id}`)
    };
    static getClientsLinks(employee_id) {
        return $authHost.get(`/admin/get/clients/${employee_id}`)
    };
    static getServicesLinks(client_id,employee_id) {
        return $authHost.get(`/admin/get/services/${client_id}/${employee_id}`)
    };
    static getPositionsLinks(service_id,client_id,employee_id) {
        return $authHost.get(`/admin/get/positions/${service_id}/${client_id}/${employee_id}`)
    };
}