import { $authHost } from "..";

export default class serviceLogHttp {
    static serviceLogAddLinks(data) {
        return $authHost.post(`/employee/serviceLog`, data)
    };
    static serviceLogRequestAddLinks(data) {
        return $authHost.post(`/employee/createRequestLog`, data)
    };
    static serviceLogDataLinks(dateRange, id) {
        return $authHost.get(`/employee/serviceLog?startDate=${dateRange?.startDate ?? ""}&endDate=${dateRange?.endDate ?? ""}&id=${id ?? ""}`)
    };
    static serviceLogDataLinksId(id) {
        return $authHost.get(`/employee/serviceLog?page=${id}`)
    };

    static serviceLogRequestsLinks() {
        return $authHost.get(`/employee/getFormsForLog`)
    };
    static serviceLogClientsLinks() {
        return $authHost.get(`/employee/get/clients`)
    };
    static serviceLogServicesLinks(id) {
        return $authHost.get(`/employee/get/services/${id}`)
    };
    static serviceLogPositionsLinks(service_id, client_id) {
        return $authHost.get(`/employee/get/positions/${service_id}/${client_id}`)
    };
    static serviceLogPositionsRequestLinks(service_id) {
        return $authHost.get(`/employee/getPositionsForRequestLogs/${service_id}`)
    };
    static serviceLogActivitiesRequestLinks(service_id) {
        return $authHost.get(`/employee/getActivitiesForRequestLogs/${service_id}`)
    };
    static serviceLogNewCasesLinks(service_id, client_id,service_type_id) {
        return $authHost.get(`/employee/get/newCases/${service_id}/${client_id}/${service_type_id}`)
    };
    static serviceLogServiceTypeLinks(service_id,client_id) {
        return $authHost.get(`/employee/get/serviceTypes/${service_id}/${client_id}`)
    };
    static serviceLogWeekLinks(id) {
        return $authHost.get(`/employee/serviceLog/week/${id}`)
    };

    static currentserviceLogLinks(id) {
        return $authHost.get(`/employee/serviceLog/${id}`)
    };
    static currentserviceLogRequestLinks(id) {
        return $authHost.get(`/employee/getRequestLog/${id}`)
    };
    static updateserviceLogLinks(id, data) {
        return $authHost.post(`/employee/serviceLog/${id}`, data)
    };
    static updateserviceLogRequestLinks(id, data) {
        return $authHost.post(`/employee/updateRequestLog/${id}`, data)
    };
    static serviceLogDeferenceLinks(data) {
        return $authHost.post(`/employee/distance`, data)
    };
    static serviceLogDeleteLinks(id) {
        return $authHost.post(`/employee/removeServiceLog/${id}`)
    };
    static serviceLogDeleteRequestLinks(id) {
        return $authHost.post(`/employee/deleteRequestLog/${id}`)
    };

}