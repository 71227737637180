export const columnsRequestTypesClient = (onClickFill) => [
  {
    accessorKey: 'title',
    header: 'Request Type',
    // hishem avelacnem
    size: 130,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.title}</p>,
  },
  {
    accessorKey: 'provider company',
    header: 'Provider Company',
    // hishem avelacnem
    size: 130,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.provider}</p>,
  },
  {
    accessorKey: 'action',
    header: 'Action',
    size: 150, //decrease the width of this column
    // maxSize: 250,
    Cell: ({ row }) => (
      <div className="flex gap-2 cursor-pointer">
       <button
                className="text-[#4885ED]  border px-2 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900 "
                onClick={() => onClickFill(row?.original?.id)}
                >
                Enter
              </button>
      </div>
    ),
  },
];
