export const EditFormAdminDefaultValue = form => {
  return {
    service_id: form?.service_id,
    name: form?.name,
    acceptable_hours: form?.acceptable_hours,
    hourly_rate: form?.hourly_rate,
    deadline: form?.deadline,
    completion_date: form.completion_date,
    review: form?.review,
    formFields: form?.formFields?.map(({ id, name, label, type, value, options_selected }) => ({
      id: id,
      name: name,
      label: label,
      type: type,
      value: value,
      options:
        type === 'checkbox'
          ? options_selected?.map(elem => ({
              name: elem?.name,
              [elem?.name]: elem?.[elem?.name],
              // a:true
            }))
          : null,
    })),
  };
};

export default EditFormAdminDefaultValue;
