export const columnsMyRequestsClient = (onClickEdit, onClickRemove, onClickEmployeeList) => [
  {
    accessorKey: 'title',
    header: 'Request Type',
    // hishem avelacnem
    size: 130,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.title}</p>,
  },
  {
    accessorKey: 'Name',
    header: 'Request Name',
    // hishem avelacnem
    size: 130,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.name}</p>,
  },
  {
    accessorKey: 'field',
    header: 'First Field',
    // hishem avelacnem
    size: 130,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.first_field}</p>,
  },
  {
    accessorKey: 'employees',
    header: 'Employee', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 140,
    Cell: ({ row }) => (
      <p
        className="whitespace-nowrap cursor-pointer"
        onClick={() => onClickEmployeeList(row?.original?.['employees']?.map(({name})=>name))}>
        {row?.original?.['employees']?.map(
          ({name}, index, array) => `${name + `${index !== array?.length - 1 ? `,` : ' '} `}`,
        )}
      </p>
    ),
  },
  {
    accessorKey: 'provider company',
    header: 'Provider Company',
    // hishem avelacnem
    size: 130,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.provider_name}</p>,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    // hishem avelacnem
    size: 130,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.status}</p>,
  },
  {
    accessorKey: 'action',
    header: 'Action',
    size: 150, //decrease the width of this column
    // maxSize: 250,
    Cell: ({ row }) => (
      <div className="flex gap-2 cursor-pointer">
        <img
          onClick={() => onClickEdit(row?.original?.id, row?.original?.status == 'Open')}
          className="cursor-pointer w-[24px] h-[24px]"
          src={row?.original?.status == 'Open' ? '/EditIcon.png' : '/EditIconDisable.png'}
          alt="EditIcon"
        />
        <img
          src={row?.original?.status == 'Open' ? '/RemoveSVG.svg' : '/RemoveSVGDissabled.svg'}
          alt="Remove"
          onClick={() => onClickRemove(row?.original?.['id'], row?.original?.status == 'Open')}
          className="cursor-pointer"
        />
      </div>
    ),
  },
];
