



export const AssignRequestToEmployeeDefaultValue = (data) => {


    return {
        employee_ids:data?.employees,
        deadline: data?.deadline,
    }
}

export default AssignRequestToEmployeeDefaultValue
