import CancleAdd from '../../components/CancleAdd/CancleAdd';
import Title from '../../components/Title/Title';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import Input from '../../components/InputLabel/InputLabel';
import Select from '../../components/Select/Select';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  editClientSlice,
  loadCitiesSlice,
  loadParentsSlice,
  loadStatesSlice,
  loadStatusesSlice,
} from '../../redux/clientsSlice/clientsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import RadioGroupWrapper from '../../components/RadioGroupWrapper/RadioGroupWrapper';
import BookTitle from '../../components/BookTitle/BookTitle';
import SearchUseFieldArray from '../../components/SearchUseFieldArray/SearchUseFieldArray';
import { yupResolver } from '@hookform/resolvers/yup';
import EditClientDefaultValue from './EditClientDefaultValue';
import { useMemo } from 'react';
import PhoneInput from '../../components/PhoneInput/PhoneInput';
import RequestErrorEditClient from './RequestErrorEditClient';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { notifySuccess, } from '../../utils/utils';
import ContractsField from './component/ContractsField/ContractsField';
import ClientHttp from '../../http/clientsHttp/clientsHttp';
import CurrencyInputNew from '../../components/CurrencyInputNew/CurrencyInputNew';
import InputLabel from '../../components/InputLabel/InputLabel';
import { loginFields, notificationMethods } from './helpers/EditClientUtils';

export default function EditClient() {
  const [isLoading, setIsLoading] = useState(false);

  const statesData = useSelector(state => state?.clients?.statesSliceData?.data);
  const citiesData = useSelector(state => state?.clients?.statesCitiesData?.data);
  const allParents = useSelector(state => state?.clients?.parentClientSliceDataAll?.data);
  const editData = useSelector(state => state?.clients?.storeClientData?.data);

  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const afterSubmit = () => {
    notifySuccess('Client successfully Changed');
    setTimeout(() => {
      navigate('/Admin/Clients');
    }, 2000);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    getValues,
    setError,
  } = useForm({
    defaultValues: useMemo(() => EditClientDefaultValue(editData, allParents), [editData, allParents]),
    resolver: yupResolver(RequestErrorEditClient()),
  });


  const {
    fields: ContractsFields,
    append: ContractsAppend,
    remove: ContractsRemove,
  } = useFieldArray({
    control,
    name: 'contacts',
  });

  const {
    fields: ServicesFields,
    append: ServicesAppend,
    remove: ServicesRemove,
  } = useFieldArray({
    control,
    name: 'services',
  });

  const onSubmit = data => {
    setIsLoading(true)
    data.services = data.services.map(e => {
      return {
        id: e.id,
        regular_rate: e?.regular_rate?.replace(/,/g, '.')?.replace(/\$/g, ''),
        rush_rate: e?.rush_rate?.replace(/,/g, '.')?.replace(/\$/g, ''),
        overtime_rate: e?.overtime_rate?.replace(/,/g, '.')?.replace(/\$/g, ''),
        invoice_code: e.code,
        position_id: e.position_id,
        start_date: e.start_date,
      };
    });
    data.group_school_id = data.comments ? data.group_school_id : null;
    data.contacts = data.contacts?.map(item => {
      return {
        id: item.id,
        firstName: item?.firstName,
        lastName: item?.lastName,
        middleName: item?.middleName,
        position: item?.position,
        state_id: item?.state_id,
        city_id: item?.city_id,
        street: item?.street,
        zipCode: item?.zipCode,
        note: item?.note,
        contact_for_service_logs:
          item?.contact_for_service_logs === '0' || item?.contact_for_service_logs === 'false' ? false : true,
        contact_for_invoices:
          item?.contact_for_invoices === '0' || item?.contact_for_service_logs === 'false' ? false : true,
        contact_phones: item?.contact_phones?.map(element => {
          return {
            id: element?.id,
            phone: element?.phone,
            ext: element?.ext,
          };
        }),
        contact_emails: item?.contact_emails?.map(element => {
          return {
            id: element?.id,
            email: element?.email,
          };
        }),
      };
    });
    ClientHttp.clientEditLinks(id, data)
      .then(afterSubmit)
      .catch(err => {
        const errorFromBackend = Object.keys(err?.response?.data?.errors);
        errorFromBackend.forEach(item =>
          setError(item, { type: 'custom', message: err?.response?.data?.errors?.[item] }),
        );
        setIsLoading(false)
      });
  };

  const currentStateValue = useWatch({ control, name: 'state_id' });

  const StateChange = e => {
    dispatch(loadCitiesSlice(e?.target?.value));
    setValue('city_id', '');
  };

  const showSelectParentCompany = useWatch({ control, name: 'comments' }); // nayum a real jamanakum inqy ed checkbox-y sxmela te che u yst dra jogum a cuyc ta selecty te che

  const onClickAddService = () => {
    ServicesAppend({});
  };

  
  const onClickAddService2 = index => {
      ServicesAppend({
        name: getValues()?.services?.[index]?.name,
        id: getValues()?.services?.[index]?.id,
        position_id: getValues()?.services?.[index]?.position_id,
        positions: getValues()?.services?.[index]?.position,
        code: getValues()?.services?.[index]?.code,
        disableField: true,
      });
  };

  const onClickCancle = () => navigate('/Admin/Clients');
  const today = new Date().toISOString().split('T')[0];

  useEffect(() => {
    dispatch(editClientSlice(id));
    dispatch(loadStatesSlice());
    dispatch(loadStatusesSlice());
    dispatch(loadParentsSlice());
  }, []);

  useEffect(() => {
    currentStateValue && dispatch(loadCitiesSlice(currentStateValue));
  }, [currentStateValue]);

  const stateCityCurrentID = useWatch({ control, name: `city_id` });
  useEffect(() => {
    setValue('city_id', stateCityCurrentID);
  }, [citiesData]);

  useEffect(() => {
    reset(EditClientDefaultValue(editData, allParents));
  }, [editData, allParents]);

  return (
    <div className="sm:p-8 p-4 w-full">
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-4">
          <Title title={'Edit Client'} className="border-b py-1" classNameTitle={'text-2xl font-semibold'} />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full  mt-2">
          <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-3 sm:gap-6 gap-2">
            {loginFields(citiesData, statesData, StateChange)?.map(e =>
              e?.currentState ? (
                <div key={e.id} className="w-full bg-white">
                  <Select
                    register={register}
                    title={e?.currentState?.labelText}
                    selectData={e?.currentState?.selectData}
                    selectClass="flex-col gap-2"
                    onChange={e?.currentState?.onChange}
                    name={e?.currentState?.name}
                    className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                    classNameTitle="text-[#60657B] opacity-50 text-xs"
                  />
                  {errors && <p className="mb-0 text-sm text-amber-800">{errors?.state_id?.message}</p>}
                </div>
              ) : e?.currentCity ? (
                <div key={e?.id} className="w-full bg-white">
                  <Select
                    register={register}
                    title={e?.currentCity?.labelText}
                    selectData={e?.currentCity?.selectData}
                    selectClass="flex-col gap-2"
                    name={e?.currentCity?.name}
                    className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                    classNameTitle="text-[#60657B] opacity-50 text-xs"
                  />
                  {errors && <p className="mb-0 text-sm text-amber-800">{errors?.city_id?.message}</p>}
                </div>
              ) : e?.currentStatus ? (
                <div key={e?.id} className="w-full bg-white">
                  <Select
                    register={register}
                    title={e?.currentStatus?.labelText}
                    selectData={e?.currentStatus?.selectData}
                    selectClass="flex-col gap-2"
                    name={e?.currentStatus?.name}
                    className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                    classNameTitle="text-[#60657B] opacity-50 text-xs"
                  />
                  {errors && <p className="mb-0 text-sm text-amber-800">{errors?.city_id?.message}</p>}
                </div>
              ) : e?.name == 'phone' ? (
                <div key={e?.id}>
                  <PhoneInput
                    className="border-b-[0.5px] box-border px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                    labelTitle={e?.labelText}
                    onKeyPress={e?.onKeyPress}
                    name={'phone'}
                    control={control}
                    classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
                  />
                  {errors && <p className="mb-0 text-sm text-amber-800">{errors?.[e?.name]?.message}</p>}
                </div>
              ) : (
                <div key={e?.id}>
                  <Input
                    labelTitle={e?.labelText}
                    inputId={e?.id}
                    onKeyPress={e?.onKeyPress}
                    maxLength={e?.maxLength}
                    className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                    placeholder={e?.placeholder}
                    name={`${e?.name}`}
                    register={register}
                    classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
                  />
                  {errors && <p className="mb-0 text-sm text-amber-800">{errors?.[e?.name]?.message}</p>}
                </div>
              ),
            )}
          </div>
          <div className="grid mt-10 mb-6 items-end sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-3 sm:gap-6 gap-2">
            <div className="space-y-5">
              <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id="comments"
                    aria-describedby="comments-description"
                    name="comments"
                    {...register('comments')}
                    type="checkbox"
                    className="h-4 w-4 rounded text-sm text-[#2B2D38] font-normal border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label htmlFor="comments" className="font-medium text-gray-900">
                    Subsidiary Client
                  </label>
                </div>
              </div>
            </div>
            <div>
              <p className="text-[#60657B] opacity-50 text-sm font-normal mb-4">Invoice Generation Option</p>
              <RadioGroupWrapper
                className={'text-[#A6ADC9] font-normal text-sm'}
                arr={notificationMethods}
                editData={editData}
                register={register}
              />
            </div>
          </div>
          {showSelectParentCompany && (
            <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-2 sm:gap-6 gap-2">
              <div className="w-full bg-white">
                <Select
                  register={register}
                  title={'Select Parent Company'}
                  selectData={allParents?.map(elem => ({
                    name: elem?.['Client Name'],
                    id: elem?.id,
                  }))}
                  selectClass="flex-col gap-2"
                  name={'group_school_id'}
                  className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                  classNameTitle="text-[#60657B] opacity-50 text-xs"
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.group_school_id?.message}</p>}
              </div>
            </div>
          )}
          {/* contact form */}
          <div className="mt-12 py-4 sm:px-7 px-4">
            <div className="">
              <BookTitle
                title="Service types"
                className="text-2xl text-[#2B2D38] font-medium"
                image={'/serviceType.svg'}
              />
            </div>
            <div className=" border mt-5 p-3 pt-2 rounded-lg">
              <Title title={'Service List'} className="py-1" classNameTitle={'text-base font-medium'} />
              {ServicesFields.map((item, servicesIndex) => (
                <div key={item.id}>
                  <div className="bg-[#FAFAFD] w-full p-1 flex xl:items-center flex-col xl:flex-row gap-2">
                    <SearchUseFieldArray
                      setValue={setValue}
                      servicesIndex={servicesIndex}
                      editData={editData}
                      getValues={getValues}
                      register={register}
                    />
                    <CurrencyInputNew
                      classNameLabel="text-[#60657B] opacity-50 text-xs font-normal mb-2"
                      className={'w-full border outline-none rounded border p-1 text-center'}
                      name={`services.${servicesIndex}.regular_rate`}
                      setValue={setValue}
                      placeholder="Regular Rate $ 0.00"
                      defaultValue={editData?.services?.[servicesIndex]?.regular_rate}
                      labelTitle={'Regular Rate'}
                    />
                    <CurrencyInputNew
                      classNameLabel="text-[#60657B] opacity-50 text-xs font-normal mb-2"
                      className={'w-full border outline-none rounded border p-1 text-center'}
                      name={`services.${servicesIndex}.rush_rate`}
                      setValue={setValue}
                      placeholder="Rush Rate $ 0.00"
                      defaultValue={editData?.services?.[servicesIndex]?.rush_rate}
                      labelTitle={'Rush Rate'}
                    />
                    <CurrencyInputNew
                      classNameLabel="text-[#60657B] opacity-50 text-xs font-normal mb-2"
                      className={'w-full border outline-none rounded border p-1 text-center'}
                      name={`services.${servicesIndex}.overtime_rate`}
                      setValue={setValue}
                      placeholder="Overtime Rate $ 0.00"
                      labelTitle={'Overtime Rate'}
                      defaultValue={editData?.services?.[servicesIndex]?.overtime_rate}
                    />
                    <div>
                      <InputLabel
                        classNameInput="flex flex-col w-full"
                        classNameLabel={'text-[#60657B] text-xs font-normal  opacity-50 mb-2'}
                        className="w-full border px-[4px] py-[3px]"
                        type="date"
                        labelTitle={'Start Date'}
                        max={today}
                        serviceList={true}
                        register={register}
                        name={`services.${servicesIndex}.start_date`}
                      />
                    </div>
                    <button
                      type="button"
                      className="w-8 h-8 rounded border border-[#E4E6F2] text-[#60657B] mt-6"
                      onClick={() => onClickAddService2(servicesIndex)}>
                      +
                    </button>
                    {servicesIndex != 0 && (
                      <button
                        type="button"
                        className="w-8 h-8 rounded border border-[#E4E6F2] text-[#60657B] mt-6"
                        onClick={() => ServicesRemove(servicesIndex)}>
                        X
                      </button>
                    )}
                  </div>
                  {errors && (
                    <p className="mb-0 text-sm text-amber-800">
                      {errors?.services?.[servicesIndex]?.id?.message ||
                        errors?.services?.[servicesIndex]?.regular_rate?.message ||
                        errors?.services?.[servicesIndex]?.rush_rate?.message ||
                        errors?.services?.[servicesIndex]?.position_id?.message ||
                        errors?.services?.[servicesIndex]?.overtime_rate?.message ||
                        errors?.services?.[servicesIndex]?.start_date?.message}{' '}
                    </p>
                  )}
                  {servicesIndex === ServicesFields?.length - 1 && (
                    <section>
                      <button
                        className="text-[#2B2D38] p-2 opacity-50 text-base"
                        type="button"
                        onClick={onClickAddService}>
                        + Add Service
                      </button>
                    </section>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="w-full bg-white rounded-md">
            <div className="py-4 sm:px-7 px-4">
              <Title
                title={'Contact Information'}
                className="border-b py-1"
                classNameTitle={'text-2xl font-semibold'}
              />
            </div>
            <ContractsField
              ContractsFields={ContractsFields}
              register={register}
              errors={errors}
              control={control}
              ContractsAppend={ContractsAppend}
              ContractsRemove={ContractsRemove}
            />
          </div>
          <div className="mt-12">
            <CancleAdd buttonText={'Save'} onClickCancle={onClickCancle} disabled={isLoading} />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
