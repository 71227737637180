

export const AddServiceLogRequestAdminDefaultValue = (requestsData) => {
    const StartDate = new Date()
    StartDate.setMinutes("00")
    StartDate.setHours("10")
    StartDate.setSeconds('00');

    const EndDate = new Date()
    EndDate.setMinutes("00")
    EndDate.setHours("11")
    EndDate.setSeconds('00');

    return {
        school_id: requestsData?.[0]?.school_id,
        service_id: requestsData?.[0]?.service_id,
        serviceLogTimeActivities: [{
            start_time: StartDate,
            end_time: EndDate,
            activiteee : {},
            position_id:"",
        }],
    }
}

export default AddServiceLogRequestAdminDefaultValue
