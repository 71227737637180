import { $authHost, $authHostt } from "..";

export default class AdminServiceLogHttp {
    static serviceLogAddLinks(data) {
        return $authHost.post(`/employee/serviceLog`, data)
    };
    static leaveComment(data) {
        return $authHost.post(`/admin/comment`, data)
    };
    static serviceLogDataLinks(dateRange, id,dataEmployeeCurrent,status,page,dataClientCurrent,dataPublishCurrent) {
        return $authHost.get(`/admin/serviceLog?startDate=${dateRange?.startDate ?? ""}&endDate=${dateRange?.endDate ?? ""}&id=${id ?? ""}&employee_id=${dataEmployeeCurrent ?? ""}&status=${status ?? ""}&page=${page ?? ""}&school_id=${dataClientCurrent??""}&publish=${dataPublishCurrent??""}`)
    };
    static customServiceLogDataLinks(dateRange, id,dataEmployeeCurrent,page,dataClientCurrent,dataPublishCurrent) {
        return $authHost.get(`/admin/customLog?id=${id ?? ""}&employee_id=${dataEmployeeCurrent ?? ""}&page=${page ?? ""}&school_id=${dataClientCurrent??""}&publish=${dataPublishCurrent??""}`)
    };
    static getEmployeesCustomBySchoolId(id, dateRange,currentParentId,currentRequest) {
        console.log(currentRequest,"currentRequest");
        
        return $authHost.get(`/admin/getEmployeesCustom?school_id=${id??""}&group_school_id=${currentParentId??""}&startDate=${dateRange?.startDate ?? ""}&endDate=${dateRange?.endDate ?? ""}&included_request_log=${currentRequest}`);
    };
    static getCasesCustomBySchoolId(id, dateRange,currentParentId) {
        return $authHost.get(`/admin/getCasesCustomLogs?school_id=${id??""}&group_school_id=${currentParentId??""}&startDate=${dateRange?.startDate ?? ""}&endDate=${dateRange?.endDate ?? ""}`);
    };
    static createCustomLog(data) {
        return $authHost.post(`/admin/customLog`, data);
    };
    static createCustomLogCase(data) {
        return $authHost.post(`/admin/customLogWithCase`, data);
    };
    static EditCustomLog(id,data) {
        return $authHost.post(`/admin/customLog/${id}`, data);
    };
    static serviceLogDataLinksId(id) {
        return $authHost.get(`/employee/serviceLog?page=${id}`)
    };
    static serviceLogClientsLinks() {
        return $authHost.get(`/employee/get/clients`)
    };
    static currentserviceLogRequestLinks(id) {
        return $authHost.get(`/admin/getRequestLog/${id}`)
    };
    static serviceLogServicesLinks(id) {
        return $authHost.get(`/employee/get/services/${id}`)
    };
    static serviceLogPositionsLinks(id) {
        return $authHost.get(`/employee/get/positions/${id}`)
    };
    static serviceLogPositionsRequestLinks(id) {
        return $authHost.get(`/admin/getPositionsForRequestLogs/${id}`)
    };
    static serviceLogActivitiesRequestLinks(id) {
        return $authHost.get(`/admin/getActivitiesForRequestLogs/${id}`)
    };
    static serviceLogWeekLinks(id) {
        return $authHost.get(`/employee/serviceLog/week/${id}`)
    };
    static currentserviceLogLinks(id) {
        return $authHost.get(`/employee/serviceLog/${id}`)
    };

    static serviceLogDeferenceLinks(data) {
        return $authHost.post(`/employee/distance`, data)
    };
    static serviceLogAdminDataLinksId(id) {
        return $authHost.get(`/admin/serviceLog?page=${id}`)
    };
    static serviceLogSendGetListLogs(client_id,dateRange,currentParentId) {
        return $authHost.get(`/admin/serviceLogByClient?school_id=${client_id??""}&startDate=${dateRange?.startDate ?? ""}&endDate=${dateRange?.endDate ?? ""}&group_school_id=${currentParentId??""}`)
    };
    static serviceLogPublishGetListLogs(client_id,dateRange,currentParentId) {
        return $authHost.get(`/admin/serviceLogByClientForPublish?school_id=${client_id??""}&startDate=${dateRange?.startDate ?? ""}&endDate=${dateRange?.endDate ?? ""}&group_school_id=${currentParentId??""}`)
    };
    static customLogSendGetListLogs(client_id,dateRange,currentParentId) {
        return $authHost.get(`/admin/customLogByClient?school_id=${client_id??""}&startDate=${dateRange?.startDate ?? ""}&endDate=${dateRange?.endDate ?? ""}&group_school_id=${currentParentId??""}`)
    };
    static customLogSendGetListLogCase(client_id,dateRange,currentParentId) {
        return $authHost.get(`/admin/customLogByClientWithCase?school_id=${client_id??""}&startDate=${dateRange?.startDate ?? ""}&endDate=${dateRange?.endDate ?? ""}&group_school_id=${currentParentId??""}`)
    };
    static customLogPublishGetListLogs(client_id,dateRange,currentParentId) {
        return $authHost.get(`/admin/customLogByClientForPublish?school_id=${client_id??""}&startDate=${dateRange?.startDate ?? ""}&endDate=${dateRange?.endDate ?? ""}&group_school_id=${currentParentId??""}`)
    };
    static customLogPublishGetListLogsCase(client_id,dateRange,currentParentId) {
        return $authHost.get(`/admin/customLogByClientWithCaseForPublish?school_id=${client_id??""}&startDate=${dateRange?.startDate ?? ""}&endDate=${dateRange?.endDate ?? ""}&group_school_id=${currentParentId??""}`)
    };
    static serviceLogWeekAdminLinks(id,employeId) {
        return $authHost.get(`/admin/serviceLog/week/${id}/employee/${employeId}`)
    };
    static serviceLogWeekAdminPDFLinks(id,employeId,clientId) {
        return $authHostt.get(`/admin/pdf/week/${id}/employee/${employeId}/client/${clientId}`)
    };
    static serviceLogWeekAdminSendLinks(id,employeId,clientId) {
        return $authHostt.post(`/admin/send/pdf/week/${id}/employee/${employeId}/client/${clientId}`)
    };
    static customLogWeekAdminSendLinks(id,) {
        return $authHostt.post(`/admin/sendCustomLog/pdf/${id}`)
    };
    static serviceLogWeekAdminMoveLinks(id,employeId,clientId) {
        return $authHostt.post(`/admin/move/week/${id}/employee/${employeId}/client/${clientId}`)
    };
    static serviceLogWeekAdminMoveLinksCustom(id) {
        return $authHostt.post(`/admin/move/customLog/${id}`)
    };
    static serviceLogWeekAdminUnMoveLinksCustom(id) {
        return $authHostt.post(`/admin/unMove/customLog/${id}`)
    };
    static serviceLogWeekAdminUnMoveLinks(id,employeId,clientId) {
        return $authHostt.post(`/admin/unMove/week/${id}/employee/${employeId}/client/${clientId}`)
    };
    static serviceLogRemaindAdminLinks(id,employeId) {
        return $authHost.post(`/admin/remind/week/${id}/employee/${employeId}`)
    };
    static serviceLogDeleteAdminLinks(id,employeId) {
        return $authHost.post(`/admin/removeWeek/week/${id}/employee/${employeId}`)
    };
    static serviceLogReturnAdminLinks(id,employeId) {
        return $authHost.post(`/admin/returnWeek/week/${id}/employee/${employeId}`)
    };
    static serviceLogDiscardAdminLinks(id,employeId) {
        return $authHost.post(`/admin/discard/week/${id}/employee/${employeId}`)
    };
    static serviceLogApporveAdminLinks(id,employeId) {
        return $authHost.post(`/admin/approve/week/${id}/employee/${employeId}`)
    };
    static serviceLogDisApporveAdminLinks(id,employeId) {
        return $authHost.post(`/admin/disapproveServiceLog/week/${id}/employee/${employeId}`)
    };
    static serviceLogSendLogsLinks(data) {
        return $authHost.post(`/admin/serviceLog/sendPdfs`,data)
    };
    static serviceLogPublishLinks(data) {
        return $authHost.post(`/admin/serviceLog/moveLogs`,data)
    };
    static customLogSendLogsLinks(data) {
        return $authHost.post(`/admin/sendCustomLogs`,data)
    };
    static customLogPublishLogsLinks(data) {
        return $authHost.post(`/admin/moveLogsToClient`,data)
    };

    static serviceLogGetCurrentLinks(id) {
        return $authHost.get(`/admin/getServiceLog/${id}`)
    };
    static serviceLogEmployeeInfoLinks(id) {
        return $authHost.get(`/admin/getEmployeeItems/${id}`)
    };
    static serviceLogRequestEmployeeInfoLinks(id) {
        return $authHost.get(`/admin/getFormsForLog/${id}`)
    };
    static serviceLogDeleteCurrentLinks(id) {
        return $authHost.post(`/admin/removeServiceLog/${id}`)
    };
    static serviceLogUpdateCurrentLinks(id,data) {
        return $authHost.post(`/admin/updateServiceLog/${id}`,data)
    };
    static serviceLogCreateAdminLinks(id,data) {
        return $authHost.post(`/admin/CreateServiceLog/${id}`,data)
    };
    static serviceLogRequestCreateAdminLinks(id,data) {
        return $authHost.post(`/admin/createRequestLog/${id}`,data)
    };
    static serviceLogRequestEditAdminLinks(id,data) {
        return $authHost.post(`/admin/updateRequestLog/${id}`,data)
    };
    static getClientsLinks(employee_id) {
        return $authHost.get(`/admin/get/clients/${employee_id}`)
    };
    static getServicesLinks(client_id,employee_id) {
        return $authHost.get(`/admin/get/services/${client_id}/${employee_id}`)
    };
    static getPositionsLinks(service_id,client_id,employee_id) {
        return $authHost.get(`/admin/get/positions/${service_id}/${client_id}/${employee_id}`)
    };
    static getServiceTypesLinks(service_id,employee_id,client_id) {
        return $authHost.get(`/admin/get/serviceTypes/${service_id}/${employee_id}/${client_id}`)
    };
    static getNewCasesLinks(service_id,client_id,service_type_id,employee_id) {
        return $authHost.get(`/admin/get/newCases/${service_id}/${client_id}/${service_type_id}/${employee_id}`)
    };
    static getCustomPdf(id) {
        return $authHostt.get(`/admin/customLog/pdf/${id}`);
    };
    static getCustomLog(id) {
        return $authHost.get(`/admin/customLog/${id}`);
    };
    static deleteCustom(id) {
        return $authHost.post(`/admin/customLogDelete/${id}`);
    };
    static deleteServiceLogRequestAdmin(id) {
        return $authHost.post(`/admin/deleteRequestLog/${id}`);
    };
}