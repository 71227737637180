import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';

import Pagination from '../../components/Pagination/Pagination';
import { useNavigate } from 'react-router-dom';
import TableClients from '../../components/DataTable/TableClients';
import EmployeesHttp from '../../http/employeesHttp/employeesHttp';
import ClientHttp from '../../http/clientsHttp/clientsHttp';
import {
  afterMove,
  afterSend,
  afterUnMove,
  columnsRequestServiceLogsClient,
} from './helpers/RequestServiceLogsClientUtils';

import 'react-toastify/dist/ReactToastify.css';
import requestHttp from '../../http/requestHttp/requestHttp';
import TitleBlockRequestServiceLogsClient from '../../components/TitleBlockRequestServiceLogsClient/TitleBlockRequestServiceLogsClient';
import InvoiceModal from '../../components/Modal/InvoiceModal';

export default function RequestServiceLogsClient() {
  const [dataId, setDateId] = useState('');
  const [data, setData] = useState([]);
  const [dataPage, setDataPage] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [fileUrl, setFileUrl] = useState();
  const [currentPage, setCurrentPage] = useState('');
  const [AllEmployeesData, setAllEmployeesData] = useState([]);
  const [dataEmployeeCurrent, setDataEmployeeCurrent] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchData = async (id, e_id, is_send, page) => {
    try {
      const response = await requestHttp.getRequestPdfClient(id);
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      setFileUrl({ url, filename: `RequestLog_${e_id}`, id: e_id, is_send: is_send, page: page });
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };

  const onClickView = (e, e_id, is_send, page) => {
    setIsOpen(true);
    fetchData(e, e_id, is_send, page);
  };

  const onPaginationClick = id => {
    requestHttp.getRequestLogsPaginationList(dataEmployeeCurrent, id).then(e => {
      setDataPage(e?.data);
      setData(e?.data?.data);
    });
    EmployeesHttp.employeesAllByClientLinks()
      .then(e => {
        const uniqueIdsSet = new Set();
        const employesList = e?.data?.data
          ?.map(item => ({
            id: item?.id || item?.[0]?.employee_id,
            name: item?.full_name || item?.[0]?.full_name,
          }))
          .reduce((result, item) => {
            if (!uniqueIdsSet.has(item.id)) {
              uniqueIdsSet.add(item.id);
              result.push(item);
            }

            return result;
          }, []);
        employesList.unshift({ id: '', name: 'All Employees' });
        employesList?.length
          ? setAllEmployeesData(employesList)
          : setAllEmployeesData([{ id: '', name: 'All Employees' }]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    EmployeesHttp.employeesAllByClientLinks()
      .then(e => {
        const uniqueIdsSet = new Set();
        const employesList = e?.data?.data
          ?.map(item => ({
            id: item?.id || item?.[0]?.employee_id,
            name: item?.full_name || item?.[0]?.full_name,
          }))
          .reduce((result, item) => {
            if (!uniqueIdsSet.has(item.id)) {
              uniqueIdsSet.add(item.id);
              result.push(item);
            }

            return result;
          }, []);
        employesList.unshift({ id: '', name: 'All Employees' });
        employesList?.length
          ? setAllEmployeesData(employesList)
          : setAllEmployeesData([{ id: '', name: 'All Employees' }]);
      })
      .finally(() => {
        setLoading(false);
      });
    requestHttp.getRequestLogsClient(dataEmployeeCurrent, '').then(e => {
      setDataPage(e?.data);
      setData(e?.data?.data);
    });
  }, [dataId, dataEmployeeCurrent]);

  return (
    <div className="w-full sm:p-8 p-2 overflow-hidden">
      <InvoiceModal open={isOpen} setOpen={setIsOpen} fileUrl={fileUrl} sendable={true} />
      <TitleBlockRequestServiceLogsClient
        src={'/InvoicesIcon.svg'}
        textItem={'Request Service Logs'}
        className="text-base font-bold"
        setDataEmployeeCurrent={setDataEmployeeCurrent}
        AllEmployeesData={AllEmployeesData}
        setCurrentPage={setCurrentPage}
        setDateId={setDateId}
      />
      <div className="px-4 py-1 mt-4 bg-white rounded-xl">
        <div className="w-full overflow-x-auto">
          <div className="inline-block min-w-[1200px]">
            <TableClients
              tbody={data ?? []}
              maxHeight="600px"
              columns={columnsRequestServiceLogsClient(onClickView, dataPage)}
            />
          </div>
        </div>
        <Pagination onPaginationClick={onPaginationClick} paginationData={dataPage.links} />
      </div>
      <ToastContainer />
    </div>
  );
}
